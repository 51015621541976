<template>
  <footer class="app-footer">
    <div
      class="app-container d-flex pt-4 pb-4 align-end flex-column align-sm-center flex-sm-row justify-sm-space-between"
    >
      <div
        class="app-footer__info d-flex justify-start align-start align-md-center"
      >
        <ApprovedIcon />
        <p class="ml-2 font-weight-bold">
          "{{ new Date().getFullYear() }} © Audit Angel"
          <!-- NICE
          <span>(National Institute for Health and Care Excellence)</span>
          Compliant -->
        </p>
      </div>
      <router-link to="/support" class="app-footer__link" exact>
        <p class="font-weight-bold">Get Support</p>
      </router-link>
    </div>
  </footer>
</template>

<script>
import ApprovedIcon from "../icons/ApprovedIcon.vue";

export default {
  name: "AppFooter",

  components: {
    ApprovedIcon,
  },
};
</script>

<style lang="scss" scoped>
.app-footer {
  background-color: var(--v-primary-base);
  color: var(--v-on-primary-base);
  min-height: 56px;
  width: 100%;

  .app-container {
    height: 100%;
  }

  &__info svg {
    width: 16.5px;
    height: 16.5px;
  }

  &__link a,
  p {
    color: var(--v-on-primary-base);
    text-decoration: none;
  }
}
</style>
